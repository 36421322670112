import styled from "styled-components"
import Colors from "ui/colors"

const Styled = {
  OurStory: styled.div`
    text-align: center;
    padding: 100px 50px 0;

    @media (max-width: 950px) {
      padding: 50px 35px;
    }

    p {
      font-size: 24px;
      color: ${Colors.darkGray};
      line-height: 1.5;
      max-width: 975px;
      margin: 50px auto;
      font-weight: 100;

      @media (max-width: 950px) {
        text-align: left;
        font-size: 20px;
        margin: 25px 0;
      }
    }
  `,

  Partners: styled.div`
    text-align: center;
    padding: 100px 50px;

    @media (max-width: 950px) {
      padding: 0 35px 50px;
    }

    p {
      font-size: 24px;
      color: ${Colors.darkGray};
      line-height: 1.5;
      max-width: 975px;
      font-weight: 100;
      margin: 50px auto;

      @media (max-width: 950px) {
        text-align: left;
        font-size: 20px;
        max-width: auto;
        margin: 25px 0 50px;
      }
    }
  `,

  PartnerLogos: styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-gap: 100px;
    align-items: center;
  `,

  PartnerLogo: styled.div`
    width: 100%;
  `,
}

export default Styled
