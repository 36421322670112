import styled from "styled-components"
import Colors from "ui/colors"

const Styled = {
  OurTeam: styled.div`
    text-align: center;
    padding: 0 100px 100px;

    p {
      font-size: 24px;
      color: ${Colors.darkGray};
      line-height: 1.5;
      max-width: 975px;
      margin: 50px auto;
      font-weight: 100;
    }
  `,

  TeamGrid: styled.div`
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); */
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px 20px;
  `,
}

export default Styled
