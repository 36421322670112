import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Components
import { Title } from "components/typography"
import Section from "../common/section.component"

// Styles
import Styled from "./our-story.styled"
import Container from "../common/container.component"

const OurStory = () => {
  const data = useStaticQuery(graphql`
    query {
      ourStory: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(our-story.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              pageTitle
            }
            html
          }
        }
      }

      partners: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(partners.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              pageTitle
            }
            html
          }
        }
      }

      partnerLogos: allFile(
        filter: {
          relativeDirectory: { eq: "partners" }
          extension: { regex: "/(png)/" }
        }
      ) {
        ...PartnerLogos
      }
    }
  `)

  const { pageTitle: ourStoryTitle } = data.ourStory.edges[0].node.frontmatter
  const { html: ourStoryHtml } = data.ourStory.edges[0].node

  const { pageTitle: partnersTitle } = data.partners.edges[0].node.frontmatter
  const { html: partnersHtml } = data.partners.edges[0].node

  const renderPartnerLogos = () =>
    data.partnerLogos.edges.map(edge => {
      const imageData = edge.node

      return (
        <Styled.PartnerLogo key={imageData.name}>
          <Img
            alt={`${imageData.name}`}
            fluid={imageData.childImageSharp.fluid}
          />
        </Styled.PartnerLogo>
      )
    })

  return (
    <>
      <Styled.OurStory>
        <Title level={3} blue>
          {ourStoryTitle}
        </Title>
        <div dangerouslySetInnerHTML={{ __html: ourStoryHtml }} />
      </Styled.OurStory>

      <Styled.Partners>
        <Title level={3} blue>
          {partnersTitle}
        </Title>
        <div dangerouslySetInnerHTML={{ __html: partnersHtml }} />
        <Container maxWidth={750}>
          <Styled.PartnerLogos>{renderPartnerLogos()}</Styled.PartnerLogos>
        </Container>
      </Styled.Partners>
    </>
  )
}

export default OurStory
