import React from "react"

// Components
import { Footer, Layout, SEO } from "components/common"
import { OurStory } from "components/our-team-page"

const OurStoryPage = () => (
  <Layout>
    <SEO title="Our Story" />
    <OurStory />
    {/* <TeamGrid /> */}
    <Footer heading="We’d like to hear about your project" />
  </Layout>
)

export default OurStoryPage
