import styled from "styled-components"
import { Title } from "components/typography"
import Colors from "ui/colors"

const Styled = {
  TeamMember: styled.div`
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }

    p {
      font-size: 24px;
      color: ${Colors.darkGray};
      margin: 0 !important;
    }
  `,
}

export default Styled
